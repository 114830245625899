<template>
  <div class="content">
        <div class="content-main">

            <!-- 上部 -->
            <div class="content-top">
                <!-- 面包屑 -->
                <div class="breadcrumbs">
                    <i></i>
                    <a href="/">首页</a><span>></span>
                    <a>关于我们</a><span>></span>
                    <a href="#" class="active">公司简介</a>
                </div>
                <!-- 侧边栏 -->
              <div class="sidebar" style="margin-right: 15px;">
                  <ul>
                      <li>
                          <div class="sidebar-title active">
                              <span>关于我们</span>
                          </div>
                          <!-- 子菜单 -->
                          <ul class="sidebar-health">   
                            <li id="gsjj" class="health-active">公司简介</li>
                            <li id="gsry" ><a @click="gotoAbout('/honor')">公司荣誉</a></li>
                            <li id="lxwm" ><a @click="gotoAbout('/contact')">联系我们</a></li>
                          </ul>
                      </li>
                      
                  </ul>
              </div>
                <div class="top-mid f-cb fw">
                    <p class="title">公司简介</p>
                    <!-- 主体 -->
                    <div class="content-mid" style="padding-top:0px;font-size:16px;" v-html="htmlContent"></div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<style src="../../../public/static/css/company-profile.css" />
<style>
.fw{width:calc(100% - 207px);}
</style>

<script>

import * as Api from '@/api'
export default {
  data() {
    return {
      recordId: null,
      htmlContent: '',
      record: {}
    }
  },
  created() {
    this.recordId = 1
    this.loadData()
  },
  methods: {
    loadData() {
      Api.pageinfo({pageId: this.recordId}).then(response=>{
        console.log(response)
        this.record = response.data.data.detail
        this.htmlContent = this.record.content
      })
    }
  }
}

</script>